<template>
  <section>
    <Toast />
    <div class="bg-white my-2 rounded-md p-4">
      <div class="flex gap-2 items-center">
        <i class="pi pi-list"></i>
        <p class="font-bold text-lg">Configuración de bodegas a usuario</p>
      </div>
      <div class="flex mt-2">
        <div class="flex gap-3 w-8/12">
          <div class="block w-5/12">
            <label class="text-xs text-gray-600">Usuario</label>
            <div class="flex w-full gap-1">
              <AutoComplete id="usuario" @change="validacionUsuarios" @item-select="obtenerBodegasUsuario" class="w-full" inputClass="text-xs w-full" panelClass="text-xs" v-model="usuarioSeleccionado" :suggestions="listadoUsuariosBuscados" @complete="buscarUsuarios($event)" field="fullName" />
              <Button v-if="usuarioSeleccionado && usuarioSeleccionado.id" icon="pi pi-times" @click="quitarUsuario" class="p-button-danger rounded-md" />
            </div>
          </div>
          <div class="block w-5/12">
            <label class="text-xs text-gray-600">Tipo de configuración</label>
            <div class="flex w-full gap-1">
              <Dropdown @change="validarInputBodegas" class="w-full border-gray-300 rounded-md" :filter="true" v-model="tipoSeleccionado" :options="tiposConfig" optionLabel="nombre" optionValue="id" placeholder="Seleccione" :showClear="true" />
            </div>
          </div>
        </div>
        <div class="flex gap-2 items-center justify-end w-4/12">
          <p>Asignar todas las bodegas</p>
          <InputSwitch @change="asignarTodasBodegas" v-model="todasBodegas" />
          <Button label="Limpiar bodegas" @click="bodegasSeleccionadas = []" class="p-button-warning" />
        </div>
      </div>
      <div class="block w-full mt-4">
        <label class="text-xs text-gray-600">Bodega</label>
        <AutoComplete :disabled="!habilitarInputBodega" @item-select="agregarBodegas" class="w-full text-xs" inputClass="text-xs w-full" v-model="bodegaSeleccionada" :suggestions="listadoBodegas" @complete="obtenerBodegas($event)" field="name" />
      </div>
      <div v-if="bodegasSeleccionadas.length" class="flex flex-wrap mt-4 gap-2">
        <div v-for="(bodega, i) in bodegasSeleccionadas" :key="i" class="bg-blue-200 p-2 text-xs text-blue-600 rounded-md flex gap-2">
          <p>{{ bodega.name }}</p>
          <button @click="eliminarBodegas(bodega.WhsCode)"><i class="pi pi-times text-xs font-bold"></i></button>
        </div>
      </div>
      <div class="mt-2" v-else>
        <p v-if="usuarioSeleccionado" class="italic text-gray-600">Este usuario no cuenta con bodegas asignadas en este tipo de configuración</p>
      </div>
      <div class="w-full mt-2 flex justify-end">
        <Button icon="pi pi-sabe" label="Guardar" @click="guardar" class="rounded-md" />
      </div>
    </div>
  </section>
</template>
<script>
  import { onMounted, ref } from 'vue'
  import Swal from 'sweetalert2'
  import BodegasService from '../../../../services/bodegas.service'
  import UsuariosService from '../../../../services/usuarios.service'
  import ConfiguracionesService from '../../../../services/configuracion.service'
  import { useToast } from 'primevue/usetoast'
  export default {
    name: 'configuracionBodegasUsuarios',
    setup () {
      const _BodegasService = ref(new BodegasService())
      const _ConfiguracionesService = ref(new ConfiguracionesService())
      const _UsuariosService = ref(new UsuariosService())
      const toast = useToast()
      const usuarioSeleccionado = ref()
      const listadoUsuariosBuscados = ref([])
      const listadoBodegas = ref([])
      const bodegaSeleccionada = ref()
      const bodegasSeleccionadas = ref([])
      const todasBodegas = ref(false)
      const tipoSeleccionado = ref()
      const habilitarInputBodega = ref(false)
      const tiposConfig = ref([])
      const buscarUsuarios = (event) => {
        _UsuariosService.value.buscar({ fullName: event.query, limit: 10 }).then(({ data }) => {
          listadoUsuariosBuscados.value = data
        })
      }
      const obtenerBodegas = (event) => {
        _BodegasService.value.listarBodegas(event.query.toUpperCase()).then(({ data }) => {
          listadoBodegas.value = []
          for (const i of data) {
            if (bodegasSeleccionadas.value.length) {
              if (!bodegasSeleccionadas.value.some(a => a.WhsCode === i.WhsCode)) {
                const name = i.WhsCode + ' - ' + i.WhsName
                listadoBodegas.value.push({
                  ...i,
                  name
                })
              }
            } else {
              const name = i.WhsCode + ' - ' + i.WhsName
              listadoBodegas.value.push({
                ...i,
                name
              })
            }
          }
        })
      }
      const agregarBodegas = () => {
        if (bodegaSeleccionada.value.WhsCode) {
          bodegasSeleccionadas.value.push(bodegaSeleccionada.value)
        }
        bodegaSeleccionada.value = ''
      }
      const eliminarBodegas = (WhsCode) => {
        const index = bodegasSeleccionadas.value.findIndex(a => a.WhsCode === WhsCode)
        bodegasSeleccionadas.value.splice(index, 1)
      }
      const quitarUsuario = () => {
        usuarioSeleccionado.value = ''
        bodegasSeleccionadas.value = []
        validarInputBodegas()
      }
      const obtenerBodegasUsuario = () => {
        if (usuarioSeleccionado.value && usuarioSeleccionado.value.id && tipoSeleccionado.value) {
          _BodegasService.value.bodegasUsuario(usuarioSeleccionado.value.id, { tipoBodega: tipoSeleccionado.value }).then(({ data }) => {
            bodegasSeleccionadas.value = []
            for (const i of data) {
              const name = i.idBodega + ' - ' + i.bodega.WhsName
              const WhsCode = i.idBodega
              bodegasSeleccionadas.value.push({
                ...i,
                name,
                WhsCode
              })
            }
          })
        } else {
          bodegasSeleccionadas.value = []
          habilitarInputBodega.value = false
          bodegaSeleccionada.value = ''
        }
      }
      const asignarTodasBodegas = () => {
        if (todasBodegas.value) {
          _BodegasService.value.listarBodegasTodas().then(({ data }) => {
            bodegasSeleccionadas.value = []
            for (const i of data) {
              const name = i.WhsCode + ' - ' + i.WhsName
              bodegasSeleccionadas.value.push({
                ...i,
                name
              })
            }
          })
        } else {
          obtenerBodegasUsuario()
        }
      }
      const guardar = () => {
        if (bodegasSeleccionadas.value.length && usuarioSeleccionado.value && tipoSeleccionado.value) {
          const body = {
            bodegas: [],
            userId: usuarioSeleccionado.value.id,
            tipoBodega: tipoSeleccionado.value
          }
          body.bodegas = bodegasSeleccionadas.value.map(a => {
            return a.WhsCode
          })
          _BodegasService.value.asignarBodegasAUsuario(body).then(() => {
            Swal.fire(
              'Guardado',
              'Bodega asignada con éxito!',
              'success'
            ).then(() => {
              bodegasSeleccionadas.value = []
              usuarioSeleccionado.value = ''
              todasBodegas.value = false
              tipoSeleccionado.value = ''
            })
          })
        } else {
          if (!bodegasSeleccionadas.value.length) return toast.add({ severity: 'error', summary: 'Error al crear configuración', detail: 'Debes seleccionar al menos una bodega para continuar', life: 5000 })
          if (!usuarioSeleccionado.value) return toast.add({ severity: 'error', summary: 'Error al crear configuración', detail: 'Debes seleccionar al menos un usuario para continuar', life: 5000 })
          if (!tipoSeleccionado.value) return toast.add({ severity: 'error', summary: 'Error al crear configuración', detail: 'Debes seleccionar un tipo de configuración para continuar', life: 5000 })
        }
      }
      const validarInputBodegas = () => {
        if (usuarioSeleccionado.value && usuarioSeleccionado.value.id && tipoSeleccionado.value) {
          habilitarInputBodega.value = true
        } else {
          bodegaSeleccionada.value = ''
          if (!usuarioSeleccionado.value.id) {
            usuarioSeleccionado.value = ''
            habilitarInputBodega.value = false
          }
        }
        obtenerBodegasUsuario()
      }
      const obtenerTiposConfiguracion = () => {
        _ConfiguracionesService.value.listarTiposConfiguracionBodegas().then(({ data }) => {
          tiposConfig.value = data
        })
      }
      const validacionUsuarios = () => {
        if (!usuarioSeleccionado.value.id) {
          habilitarInputBodega.value = false
        }
      }
      onMounted(() => {
        obtenerTiposConfiguracion()
      })
      return {
        usuarioSeleccionado,
        listadoUsuariosBuscados,
        buscarUsuarios,
        listadoBodegas,
        bodegasSeleccionadas,
        bodegaSeleccionada,
        todasBodegas,
        obtenerBodegas,
        agregarBodegas,
        eliminarBodegas,
        quitarUsuario,
        obtenerBodegasUsuario,
        guardar,
        asignarTodasBodegas,
        tiposConfig,
        habilitarInputBodega,
        tipoSeleccionado,
        validarInputBodegas,
        validacionUsuarios
      }
    }
  }
</script>
<style scoped>
</style>
